import resetcss from './reset.css'
import stylesheet from './styles.css'
import linksJSON from './links.json'

let links = linksJSON.links
const svgIcons = require.context('./svg/', false, /\.svg$/);

function isSafariOniPhone() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIphone = /iPhone/.test(userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    return isIphone && isSafari;
}

if (isSafariOniPhone()){
    document.querySelector('body').setAttribute('style', 'background-color: #29112b');
}

const linksContainer = document.querySelector('#links')

for (let i = 0; i < links.length; i++){
    const link = links[i]
    if (link.hasOwnProperty('hide') && link.hide){
        continue
    }
    let friendlyUrl = false
    if (link.hasOwnProperty('friendlyUrl')){
        friendlyUrl = link.friendlyUrl
    }
    const svgIconPath = svgIcons(`./${link.icon}`);
    const title = link.title
    const url = link.url
    const fade = link.fade
    buildCard(title, url, friendlyUrl, svgIconPath, fade)
    if (link.hasOwnProperty('label')){
        buildLabel(link.label)
    }
}

function buildCard(title, url, friendlyUrl, iconPath, fade){
    const a = document.createElement('a')
    console.log(url.split('/'))
    a.setAttribute('href', url)
    a.setAttribute('tabindex', '-1')
    const card = document.createElement('button')
    card.classList.add('link-card')
    const icon = document.createElement('svg')
    icon.innerHTML = iconPath
    card.append(icon)
    const titleSpan = document.createElement('span')
    const urlSpan = document.createElement('span')
    titleSpan.textContent = title
    titleSpan.classList.add('friendly-title')
    if (friendlyUrl) {
        urlSpan.textContent = friendlyUrl.split('://')[1]
    }else{
        urlSpan.textContent = url.split('://')[1]
    }
    urlSpan.classList.add('url-title')
    card.append(titleSpan)
    card.append(urlSpan)
    if (fade){
        card.classList.add('fade')
    }
    a.append(card)
    linksContainer.append(a)
}

function buildLabel(text){
    const element = document.createElement('span')
    element.textContent = text
    element.classList.add('link-label')
    linksContainer.append(element)
}